/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";

const AtlantaHeartNotes = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Donation HI, USA";
    return `<gatsby_donation donationId =  ${id} btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Atlanta Heart Notes, USA" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("atlanta_heart_notes,_USA", event),
    []
  );
  return (
    <LayoutHome seoTitle="Atlanta Heart Notes - Donations">
      <Grid
        columns={1}
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        <Box>
          <div style={{ lineHeight: "1.1", marginBottom: "8px" }}>
            <h1
              style={{
                color: "#2d3550",
                fontSize: "1.5em",
                fontWeight: "600",
                marginTop: "40px",
              }}
            >
              Atlanta Heart Notes
            </h1>
          </div>
          <div>
            <p style={{ textAlign: "justify" }}>
              Donate $10 and get a copy of The Heartfulness Way.
            </p>
          </div>
        </Box>
        <Box>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent(17, "Donate Now")}
          />
        </Box>
        <Box style={{ color: "#2d3550" }}>
          <div>
            <h1
              style={{
                color: "#2d3550",
                fontSize: "1.5em",
                fontWeight: "600",
                marginTop: "40px",
              }}
            >
              Heartfulness Institute
            </h1>
          </div>
          <div>
            <p style={{ textAlign: "justify" }}>
              Since 1945, Heartfulness meditation practices have spread to
              millions of people worldwide. These practices are offered free of
              charge to people fifteen years of age and above, and Heartfulness
              Relaxation can be taught to children. Today there are thousands of
              certified Heartfulness volunteer trainers serving people in over
              130 countries. They are supported by Kamlesh D. Patel, who is also
              known as Daaji. You can learn more about Daaji at daaji.org.
            </p>
            <div>
              <p style={{ textAlign: "justify" }}>
                Heartfulness organizations manage various programs and
                facilities worldwide, including:
              </p>
              <ul>
                <li>
                  <p style={{ marginBottom: "0.5rem" }}>
                    &#x2022; Scholarships for students from all continents to
                    attend advanced training programs
                  </p>
                </li>
                <li>
                  <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Cutting-edge research on the effects of meditation
                  </p>
                </li>
                <li>
                  <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; ‘Conscious Living’ programs on values and life
                    skills for teachers and students in schools
                  </p>
                </li>
                <li>
                  <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Programs in colleges and universities, and in
                    corporate and government organizations
                  </p>
                </li>
                <li>
                  <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Programs for villagers and tribal communities
                  </p>
                </li>
                <li>
                  <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Free medical clinics
                  </p>
                </li>
                <li>
                  <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Ashrams, meditation centers, research facilities
                    and retreat centers
                  </p>
                </li>
              </ul>
              <Box>
                <Grid columns={[1, 1, 3, null]}>
                  <Box>
                    <StaticImage
                      src="../../static/imgs/hfn-int-img1.jpg"
                      alt="Logo"
                    />
                  </Box>
                  <Box>
                    <StaticImage
                      src="../../static/imgs/hfn-int-img2.jpg"
                      alt="Logo"
                    />
                  </Box>
                  <Box>
                    <StaticImage
                      src="../../static/imgs/hfn-int-img3.jpg"
                      alt="Logo"
                    />
                  </Box>
                </Grid>
                <div>
                  <p style={{ marginTop: "0px", textAlign: "justify" }}>
                    Heartfulness Institute Activities in Metro Atlanta
                  </p>
                </div>
              </Box>
              <ul>
                <li>
                  <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Free weekly group meditations in Cumming, Roswell,
                    Alpharetta, Marietta, Sandy Springs, and Norcross
                  </p>
                </li>
                <li>
                  <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Over 45 skilled trainers in metro Atlanta; all
                    meditation sessions provided free of charge
                  </p>
                </li>
                <li>
                  <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Free Relaxation and meditation programs at
                    libraries, parks, neighborhood clubhouses, corporates,
                    schools and temples.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <Box style={{ borderTop: "1px solid #c5c5c5", marginTop: "20px" }}>
            <div style={{ marginTop: "10px" }}>
              <h1
                style={{
                  color: "#2d3550",
                  fontSize: "1.5em",
                  fontWeight: "600",
                  marginTop: "20px",
                }}
              >
                SwarGanga – A River of Notes
              </h1>
            </div>
            <Box style={{ marginTop: "25px" }}>
              <StaticImage
                src="../../static/imgs/swarganga-img1.jpg"
                alt="Logo"
              />
              <p style={{ marginTop: "15px", textAlign: "justify" }}>
                <span style={{ fontSize: "1rem" }}>
                  Mrs. Apoorva Gokhale singing at the SwarGanga Music Festival
                  2010. Miss. Pallavi Joshi on Tanpura
                </span>
              </p>
            </Box>
            <Box>
              <div>
                <h4 style={{ color: "#777", fontSize: "1.2rem" }}>Our Roots</h4>
                <p style={{ textAlign: "justify" }}>
                  Ganga – the name itself evokes a deep emotion about India. Its
                  vastness, purity and divinity can probably be best compared
                  with eternal music that flows from the seven swaras. What
                  better name than SwarGanga to describe an organization that
                  works to promote Indian classical music.
                </p>
                <p style={{ textAlign: "justify" }}>
                  India has a rich musical tradition. Once considered as one of
                  the most respected professions, over the past few decades
                  Indian classical music has experienced many ups and downs. A
                  lot of music has been lost due to lack of proper
                  documentation. We have made it our mission not only to
                  preserve Indian classical music but to preserve a piece of
                  Indian culture! We at SwarGanga use all the available
                  technology resources, including web, mobile and social
                  networking, to reach out to millions of visitors each year. We
                  have been able to create the world’s largest database on North
                  Indian classical music by documenting various raags, taals,
                  bandishes, artists, gharanas or instruments, etc. in our
                  databases.
                </p>
              </div>

              <div>
                <h4 style={{ color: "#777", fontSize: "1.2rem" }}>
                  Our Mission
                </h4>
                <p style={{ textAlign: "justify" }}>
                  SwarGanga’s mission is three-fold:
                </p>
                <ul>
                  <li>
                    <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                      &#x2022; To promote North Indian classical music
                      throughout the world
                    </p>
                  </li>
                  <li>
                    <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                      &#x2022; To promote young, upcoming and less known artists
                    </p>
                  </li>
                  <li>
                    <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                      &#x2022; To document and preserve North Indian classical
                      music assets digitally
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <h4 style={{ color: "#777", fontSize: "1.2rem" }}>
                  Our Activities
                </h4>
                <ul>
                  <li>
                    <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                      &#x2022; SwarGanga Music Festival and Music Concerts
                    </p>
                  </li>
                  <li>
                    <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                      &#x2022; Crescendo
                    </p>
                  </li>
                  <li>
                    <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                      &#x2022; SwarGanga School of Music
                    </p>
                  </li>
                </ul>
                <Box>
                  <Grid columns={[1, 1, 2, null]}>
                    <Box>
                      <StaticImage
                        src="../../static/imgs/swarganga-img2.jpg"
                        alt="Logo"
                      />
                      <p style={{ marginTop: "15px", textAlign: "justify" }}>
                        <span style={{ fontSize: "1rem" }}>
                          Dr. Arawind Thatte playing Harmonium accompanied by
                          Pandit Mukundraj Deo on Tabla at SwarGanga Music
                          Festival 2007
                        </span>
                      </p>
                    </Box>
                    <Box>
                      <StaticImage
                        src="../../static/imgs/swarganga-img3.jpg"
                        alt="Logo"
                      />
                      <p style={{ marginTop: "15px", textAlign: "justify" }}>
                        <span style={{ fontSize: "1rem" }}>
                          Shri. Adwait Joshi performing at a student recital of
                          SwarGanga School of Music, accompanied by Shri. Amit
                          Chabukswar on Tabla
                        </span>
                      </p>
                    </Box>
                  </Grid>
                  <Box
                    style={{
                      borderTop: "1px solid #c5c5c5",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <h4
                        style={{
                          color: "#777",
                          fontSize: "1.2rem",
                          marginTop: "20px",
                        }}
                      >
                        Non-Profit Status and Sponsorships
                      </h4>
                    </div>
                    <p style={{ textAlign: "justify" }}>
                      Both SwarGanga and Heartfulness Institute are 501(c)(3)
                      tax- exempt charitable organizations. We hope that you
                      will become one of our sponsors and support our
                      organizations in doing their pioneering work.
                    </p>
                    <Box>
                      <StaticImage
                        src="../../static/imgs/hfn-int-sponsorships.jpg"
                        alt="Logo"
                      />
                    </Box>
                  </Box>
                </Box>
              </div>
            </Box>
          </Box>
        </Box>
      </Grid>
    </LayoutHome>
  );
};

export default AtlantaHeartNotes;
